html {
  -webkit-font-smoothing: auto !important;
}

@media (max-width: 576px) {
  body {
    overflow: hidden auto !important;
  }
}

@media (min-width: 577px) {
  body {
    padding: 0 calc(20px - (100vw - 100%)) 0 0;
  }
}

::selection {
  text-shadow: none;
  background: #b3d4fc;
}

/* fix plugin object size */

/* stylelint-disable selector-max-id */
#cadesplugin_object {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/* stylelint-enable selector-max-id */

.top .react-datepicker__month {
  transform: translateY(-100%);
  transition: none;
}

.top.visible .react-datepicker__month {
  transform: translateY(0);
  transition: 0.25s ease;
}

.top.visible + div > .react-datepicker__month {
  transform: translateY(100%);
  transition: 0.25s ease;
}

.bottom .react-datepicker__month {
  transform: translateY(100%);
  transition: none;
}

.bottom.visible .react-datepicker__month {
  transform: translateY(0);
  transition: 0.25s ease;
}

.bottom.visible + div > .react-datepicker__month {
  transform: translateY(-100%);
  transition: 0.25s ease;
}
